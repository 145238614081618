<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCreate="onCreate"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            :label="'Companies List'"
            :icon="value.icon"
            :actions="actions"
            @onCreate="onCreate"
            underline
            right
          ></FormSection>
          <v-list-item-group color="primary">
            <v-list-item
              class="a-view-list-item"
              @click="onSelect(company)"
              v-for="company in companies"
              :key="company.id"
              :input-value="company.id === activeCompanyId"
            >
              <v-list-item-content>
                <v-list-item-title v-html="company.name"></v-list-item-title>
                <!-- <v-list-item-subtitle>
                  <v-icon left>
                    {{ `fi fi-${String(company.country.iso2).toLowerCase()}` }}
                  </v-icon>
                </v-list-item-subtitle> -->

                <v-list-item-subtitle
                  class="mt-2"
                  v-if="company.currentCustomer"
                >
                  <v-icon small color="primary" class="mr-2">
                    mdi-account
                  </v-icon>

                  <v-chip @click="onCustomerSelected(company.currentCustomer)">
                    <v-avatar left>
                      <v-img
                        :style="{
                          background:
                            company.currentCustomer.user.profile.color,
                        }"
                        :src="company.currentCustomer.user.profile.avatarURL"
                      ></v-img>
                    </v-avatar>
                    {{ company.currentCustomer.user.profile.f_name }}
                  </v-chip>
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mt-2">
                  <v-icon small color="primary" class="mr-2">
                    mdi-handshake-outline
                  </v-icon>
                  <v-chip
                    v-for="companyDomain in company.companyDomains"
                    :key="companyDomain.id"
                  >
                    {{ companyDomain.domain.name }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <a class="a-icon" :href="company.website" target="blank">
                  <v-icon>mdi-open-in-new</v-icon>
                </a>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-item-group>
        </v-col>
      </v-row>
    </template>
  </AView>
</template>
    
    
    
  <script>
import { mapState } from "vuex";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { GraphUI } from "../../../../GrapUI.config.js";
import { CustomerCompanyCreation } from "../../app.gh.js";

export default {
  name: "UsersList",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
  },
  computed: mapState("CustomerCompanyStore", ["activeCompanyId", "companies"]),
  created() {
    this.$store.dispatch("CustomerCompanyStore/GetCompaniesList");
  },
  data() {
    return {
      page: 1,
      scene: GraphUI.scene,
      actions: [
        {
          name: "Create",
          description: "Will create new Customer",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
        {
          name: "Close",
          description: "Will close this window",
          icon: "mdi-trash-can-outline",
          color: "#0e1721",
          on: "onClose",
        },
      ],
    };
  },
  methods: {
    async onCreate() {
      await this.scene.add(this.value, CustomerCompanyCreation, {});
      // RView.focus();
    },
    onSelect(company) {
      // this.scene.add(this.value, CustomerDetails, {
      //   companyId: company.id,
      // });

      this.$store.dispatch("CustomerCompanyStore/GetCompaniesList", company);
    },
    async onCustomerSelected(customer) {
      this.$store.dispatch("CustomerStore/SetActiveCustomer", {
        from: this.value,
        customer,
      });
    },
  },
};
</script>
    
    
    
    <style scoped lang="scss" >
.users-list {
  height: 100%;
  width: 100%;
  // ::v-deep button {
  //   background: white !important;
  // }
}
</style>